.fillImageBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.fillImageBackground:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.7);
}

.mainImage {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.header {
    width: 100%;
    height: 100vh;
    padding-top: 40px;
    padding-bottom: 40px;
}

.iframe {
    height: calc(100vh + 120px);
    margin-top: -60px;
    margin-bottom: -60px;
}

.headerTitle {
    font-size: 64px;
    line-height: 90px;
    font-weight: bold;
    color: white;
    @media (max-width: 768px) {
        font-size: 50px;
        line-height: 64px;
    }
    @media (max-width: 425px) {
        font-size: 30px;
        line-height: 40px;
    }
}

.headerSubtitle {
    font-size: 30px;
    line-height: 34px;
    color: white;
    @media (max-width: 768px) {
        font-size: 22px;
        line-height: 26px;
    }
    @media (max-width: 425px) {
        font-size: 16px;
        line-height: 20px;
    }
}

.downArrowText {
    font-size: 16px;
    line-height: 19px;
    color: white;
}

.title {
    font-size: 26px;
    line-height: 34px;
    font-weight: bold;
    color: var(--mine-shaft);
}
.subtitle {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: var(--mine-shaft-2);
}

.text {
    font-size: 16px;
    line-height: 26px;
    color: var(--mine-shaft-2);
}
