.footer {
    height: 152px;
    position: relative;
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.imageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

}
