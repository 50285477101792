.container {
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px
}
.line {
    height: 1px;
    flex: 1;
    background-color: lightgrey;
}
.short {
    flex: 0;
    min-width: 30px;
}
.title {
    font-family: Montserrat, sans-serif;
    font-weight: 900;
    font-size: 36px;
    @media (max-width: 768px) {
        font-size: 22px;
    }
    @media (max-width: 425px) {
        font-size: 18px;
    }
}
.smallTitle {
    font-size: 26px;
    font-family: Roboto, sans-serif;
    font-weight: 600
}
