.arrowButton {
    position: absolute;
    top: 0;
    height: 100%;
    width: 60px;
}
.arrowButtonLeft {
    left: 0;
}
.arrowButtonRight {
    right: 0;
}

.image {
    width: 124px;
    height: 176px;
}
