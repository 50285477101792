.carouselContainer {
    position: relative;
    padding: 35px 0;
}

.arrowButton {
    position: absolute;
    top: 0;
    height: 100%;
    width: 60px;
}
.arrowButtonLeft {
    left: 0;
}
.arrowButtonRight {
    right: 0;
}

.image {
    width: 78px;
    height: 78px;
    object-fit: contain;
}
